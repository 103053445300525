import React, { useState } from 'react';
import Form from './Form';
import ThankYou from './ThankYou';
import { DATA_MODELS } from './Form/Field';

const FORM_ID = 'summer_camp_fair';
const PIPELINE_ID = 29;

const formConfig = [
  {
    label: 'First Name',
    name: 'First Name',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Last Name',
    name: 'Last Name',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Email',
    name: 'Email',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Phone',
    name: 'Phone',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: (
      <>
        I agree to the{' '}
        <a href="/privacy" target="_blanc">
          <b>BUNAC privacy policy</b>
        </a>{' '}
        and understand BUNAC may contact me about my event booking.
      </>
    ),
    name: 'agreed to privacy',
    isRequired: true,
    model: DATA_MODELS.person,
  },
];

const SummerCampFair = () => {
  const [submitted, setSubmited] = useState(false);
  const submit = () => {
    setSubmited(true);
  };

  if (submitted) {
    return <ThankYou />;
  }

  return (
    <Form
      headline="BUNAC Summer Camp USA Job Fairs 2020 - Save your place today!"
      formId={FORM_ID}
      config={formConfig}
      initValues={null}
      onSubmit={submit}
      pipelineId={PIPELINE_ID}
    />
  );
};

export default SummerCampFair;